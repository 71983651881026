<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <span
              >商务名称：
              <el-autocomplete
                class="ipt_width project_width"
                v-model="parameter.businessName"
                :fetch-suggestions="businessNameQuerySearch"
                placeholder="请输入或选择商务名称"
                @select="onProkectNameSelect"
                :popper-append-to-body="false"
              >
              </el-autocomplete
            ></span>
            <span>
              商务负责人：
              <el-select
                class="ipt_width"
                clearable
                v-model="parameter.userName"
                filterable
                placeholder="请输入或者选择商务负责人"
              >
                <el-option
                  v-for="item in selectedList"
                  :key="item.id"
                  :label="item.userName"
                  :value="item.userName"
                >
                </el-option>
              </el-select>
            </span>

            <el-button
              type="primary"
              icon="el-icon-search"
              @click="pageChangeHandler(1)"
              :loading="loading"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="success" style="padding: 0">
            <a
              style="display: block; height: 40px; width: 100px; line-height: 40px"
              target="_blank"
              href="https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/商务绩效核算操作文档.pdf"
              >操作文档</a
            ></el-button
          >
        </div>
      </div>

      <div class="mainList_content">
        <div class="mainList_content_sub">
          <!-- <el-tabs v-model="parameter.performanceStatus" class="fullScreenMainHeader">
            <el-tab-pane label="待核算" name="3"></el-tab-pane>
          </el-tabs> -->

          <el-table
            row-key="id"
            :data="tableData"
            border
            style="width: 100%"
            height="string"
            v-loading="loading"
          >
            <el-table-column
              align="center"
              type="index"
              label="序号"
              width="50"
              :show-overflow-tooltip="false"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="businessName"
              label="商务名称"
              :show-overflow-tooltip="false"
              min-width="320"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="ownerUnit"
              label="业主单位"
              :show-overflow-tooltip="false"
              min-width="240"
            >
            </el-table-column>
            <el-table-column align="center" prop="contacts" label="经办人" width="100">
            </el-table-column>
            <el-table-column align="center" prop="contactsPhone" label="经办人电话" width="120">
            </el-table-column>
            <el-table-column
              align="center"
              prop="principalUserName"
              label="商务负责人"
              width="120"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="businessType"
              sortable
              label="业务类型"
              width="120"
            >
              <template slot-scope="scope">
                {{ scope.row.businessType | dict(dictData.businessType) }}
              </template>
            </el-table-column>
            <!-- <el-table-column align="center" prop="performanceStatus" label="状态" width="120">
              <template slot-scope="scope">
                <span v-if="scope.row.performanceStatus == 0">暂存</span>
                <span v-if="scope.row.performanceStatus == 1">提交</span>
                <span v-if="scope.row.performanceStatus == 2">谢总审核通过</span>
                <span v-if="scope.row.performanceStatus == 21">不通过</span>
                <span v-if="scope.row.performanceStatus == 3">曾总审核通过</span>
                <span v-if="scope.row.performanceStatus == 31">不通过</span>
              </template>
            </el-table-column> -->
            <el-table-column align="center" fixed="right" label="操作" width="120">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row)"
                  type="text"
                  size="small"
                  class="text_Edit_Bgc"
                  >核算
                </el-button>
                <el-button
                  @click="showCostDialog(scope.row)"
                  :loading="isCostDialogLoading"
                  type="text"
                  size="small"
                  class="text_RESET_PASSWORD_Bgc"
                  >费用
                </el-button>
                <!-- 
                  <el-button v-if="scope.row.performanceStatus == 21"
                    @click="handleClick(scope.row)"
                    type="text"
                    class="text_Edit_Bgc"
                    size="small"
                  >
                    <span>完善绩效信息</span>
                  </el-button>
                </span>
                <span v-if="scope.row.performanceStatus == 21">
                  <el-button
                    @click="handleClick(scope.row)"
                    type="text"
                    size="small"
                    > <span>编辑绩效信息</span> </el-button
                  >
                </span> -->
                <!-- <span>
                  <el-button
                    @click="handleClick(scope.row)"
                    type="text"
                    class="text_Details_Bgc"
                    size="small"
                    >查看</el-button
                  >
                </span> -->
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <BusinessComputation
      v-if="showEditDialog"
      @updateData="getData"
      :showEditDialog.sync="showEditDialog"
      :ID="ID"
      :distriBution="distriBution"
    ></BusinessComputation>

    <EditDialog width="90%" :isShow.sync="costDialog_show" id="costDialog">
      <div slot="title" class="title">
        <el-row>
          <el-col :span="12"><div>商务记账信息</div></el-col>
          <el-col :span="12"> <div>员工记账明细</div></el-col>
        </el-row>
      </div>
      <div slot="content" class="content" style="min-height: 400px">
        <el-row>
          <el-col :span="12">
            <el-card>
              <div class="cost-dialog-title">
                <el-descriptions border size="medium">
                  <el-descriptions-item label="商务名称" labelStyle="min-width: 90px;">{{
                    costDialog_data.businessName
                  }}</el-descriptions-item>
                  <el-descriptions-item
                    label="商务负责人"
                    labelStyle="min-width: 100px;"
                    contentStyle="min-width: 90px;"
                    >{{ costDialog_data.principalUserName }}</el-descriptions-item
                  >
                </el-descriptions>
              </div>
              <el-table
                :data="costDialog_data.leftTableData"
                border
                ref="leftTableRef"
                height="350"
                style="width: 100%"
                v-loading="leftCostLoading"
                :highlight-current-row="false"
                :cell-class-name="setCellClass"
              >
                <el-table-column
                  type="index"
                  align="center"
                  label="序号"
                  width="70"
                  :show-overflow-tooltip="false"
                >
                </el-table-column>
                <el-table-column prop="targetName" width="100" align="center" label="姓名">
                </el-table-column>
                <el-table-column prop="role" width="150" align="center" label="角色">
                  <template slot-scope="scope">
                    {{ scope.row.role | dict(dictData.businessStaffRole) }}
                  </template>
                </el-table-column>
                <el-table-column prop="sumCost" align="center" label="总报销金额">
                </el-table-column>
                <el-table-column prop="sumTallyCost" align="center" label="总记账金额">
                </el-table-column>
                <el-table-column width="100" align="center" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      size="mini"
                      @click="selectUserCost(scope.row, scope.$index)"
                      >明细</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <div style="text-align: right; margin-top: 10px">
                <el-pagination
                  @size-change="handleLeftSizeChange"
                  @current-change="handleLeftCurrentChange"
                  :current-page="leftParams.pageNow"
                  :page-sizes="[5, 10, 50, 100]"
                  :page-size="10"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="leftParams.total"
                >
                </el-pagination>
              </div>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card v-loading="rightCostLoading">
              <div v-if="costDialog_data.selectId">
                <div class="cost-dialog-title">
                  <el-descriptions border :column="2" size="medium">
                    <el-descriptions-item
                      label="成员"
                      :span="1"
                      :content-class-name="[this.highlightedCell ? 'highlight' : '']"
                    >
                      {{ costDialog_data.selectName }}</el-descriptions-item
                    >
                    <el-descriptions-item label="角色" :span="1">
                      {{
                        costDialog_data.selectRole | dict(dictData.businessStaffRole)
                      }}</el-descriptions-item
                    >
                  </el-descriptions>
                </div>
                <el-table
                  :data="costDialog_data.rightTableData"
                  border
                  height="350"
                  style="width: 100%"
                >
                  <el-table-column
                    type="index"
                    align="center"
                    label="序号"
                    width="70"
                    :show-overflow-tooltip="false"
                  >
                  </el-table-column>
                  <el-table-column prop="date" align="center" label="记账时间">
                    <template slot-scope="scope">
                      {{ scope.row.createdDate | dateFormat }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="moduleType"
                    min-width="120"
                    align="center"
                    label="记账类型"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.moduleType | dict(dictData.moduleType) }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="sumCost" align="center" label="报销金额">
                  </el-table-column>
                  <el-table-column prop="costTally" align="center" label="记账金额">
                  </el-table-column>
                  <el-table-column
                    prop="staffBusinessPerformance"
                    width="120"
                    align="center"
                    label="操作"
                  >
                    <template slot-scope="scope">
                      <el-button
                        type="primary"
                        size="mini"
                        @click="showCostDetail(scope.row)"
                        :loading="rightDetailLoading"
                        >详情</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <div style="text-align: right; margin-top: 10px">
                  <el-pagination
                    @size-change="handleRightSizeChange"
                    @current-change="handleRightCurrentChange"
                    :current-page="rightParams.pageNow"
                    :page-sizes="[5, 10, 50, 100]"
                    :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="rightParams.total"
                  >
                  </el-pagination>
                </div>
              </div>
              <div class="empty-detail" v-else><span class="content">点击左侧明细</span></div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="closeDialog">返回</el-button>
      </div>
    </EditDialog>

    <BusinessPerformanceDetailDialog
      v-if="costDetailDialog_show"
      :costDetailDialog_show="costDetailDialog_show"
      :costDetail="costDialog_data.costDetail"
      :type="costDialog_data.moduleType"
      :imgList="imgList"
      @closeDialog="handleCloseCostDetail"
    ></BusinessPerformanceDetailDialog>
  </div>
</template>
<script>
import { numberToCurrencyNo, delcommafy, changeMoneyToChinese } from '@/util/jsencrypt'
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    BusinessComputation: () => import('@/views/publicView/BusinessComputation.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    DialogImg: () => import('@/components/DialogImg.vue'),
    BusinessPerformanceDetailDialog: () =>
      import('@/components/BusinessPerformanceDetailDialog.vue'),
  },
  data() {
    return {
      businessList: [],
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        businessName: null, //商务名称
        userName: null,
        status: 100,
      },
      loading: false,
      pageSize: 0,
      tableData: [],
      showEditDialog: false,
      dictData: {
        businessType: [],
        companyType: [],
        businessStaffRole: [],
        moduleType: [],
      },
      ID: '',
      distriBution: false,
      selectedList: [],

      leftParams: {
        pageSize: 10,
        pageNow: 1,
        total: 0,
        businessManageId: '',
      }, //报销信息左侧弹框的请求参数
      rightParams: {
        pageSize: 10,
        pageNow: 1,
        total: 0,
        businessManageId: '',
        targetId: '',
      }, //dialogVisible
      costDialog_data: {
        moduleType: '',
        businessName: '',
        businessManageId: '',
        principalUserName: '',
        principalUserId: '',
        selectName: '', //点击左侧明细后右侧显示姓名
        selectRole: '', //点击左侧明细后右侧显示角色
        selectId: '', //点击左侧明细后右侧显示id
        // 左侧商务报销信息（按员工划分
        leftTableData: [],
        rightTableData: [], //右侧员工明细
        costDetail: {},
      },
      costDialog_show: false, //报销信息弹框显示隐藏
      costDetailDialog_show: false,

      isCostDialogLoading: false, //报销详情button按钮的loading
      leftCostLoading: false, //弹框左侧表格loading
      rightCostLoading: false, //弹框右侧表格loading
      rightDetailLoading: false,

      img_index: 0, //附件图片预览下标
      imgList: [], //附件图片数组
      dialogVisible: false, //图片预览
      filepath: process.env.VUE_APP_FILEPATH,

      highlightedCell: '',
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      allUserList: state => state.business.allUserList,
    }),

    // 报销单金额合计
    amount() {
      let sum = 0
      if (this.costDialog_data.costDetail.detailList) {
        sum = this.costDialog_data.costDetail.detailList.reduce(
          (total, item) => (total += Number(item.cost)),
          0
        )
      }
      return sum
    },
  },
  filters: {
    applyAmount(n) {
      if (!n) return
      return Number(n).toLocaleString()
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.$api.businessExpense
      .businessNameList()
      .then(res => {
        this.businessList = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BUSINESS_TYPE', true)
      .then(res => {
        this.dictData.businessType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('COMPANY_TYPE', true)
      .then(res => {
        this.dictData.companyType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BUSINESS_MODULE_TYPE', true)
      .then(res => {
        this.dictData.moduleType = res.data
      })
      .catch(err => {
        console.log(err)
      })

    this.$api.dict
      .listSysDictData('BUSINESS_STAFF_ROLE', true)
      .then(res => {
        this.dictData.businessStaffRole = res.data
      })
      .catch(err => {
        console.log(err)
      })
    if (this.allUserList.length <= 0) {
      this.getSelectedList()
    } else {
      this.selectedList = this.allUserList
    }
  },
  watch: {
    'parameter.performanceStatus': {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal) {
        this.parameter.pageNow = 1
        this.getData()
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },
  methods: {
    businessNameQuerySearch(queryString, cb) {
      let businessList = this.businessList.map(item => {
        return { value: item.businessName }
      })
      let results = queryString ? this.businessNameCreateFilter(queryString) : businessList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    businessNameCreateFilter(queryString) {
      let results = this.businessList.map(item => {
        return { value: item.businessName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },
    onProkectNameSelect(e) {
      if (e) {
        this.parameter.businessName = e.value
      }
      this.$forceUpdate()
    },
    // 获取所有人员
    getSelectedList() {
      this.$api.businessManage
        .principalUserNameListNoPage()
        .then(res => {
          this.selectedList = res.data
          this.$store.commit({
            type: 'business/SET_ALL_USER_LIST',
            allUserList: res.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    dataUpdate() {
      this.$forceUpdate()
    },
    handleClick(row) {
      this.distriBution = true
      this.ID = row.businessManageId
      this.showEditDialog = true
    },
    async getData() {
      this.loading = true
      const res = await this.$api.businessPerformance.allList(this.parameter)
      if (res.data == null) {
        let arr = []
        this.tableData = arr
      } else {
        this.tableData = res.data.records
        this.parameter.total = res.data.total
      }
      this.loading = false
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    handleReset() {
      this.parameter.pageNow = 1
      this.parameter.businessName = null
      this.parameter.userName = null
      this.parameter.status = 100
      this.getData()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    // 查看商务支出信息
    showCostDialog(scope) {
      this.costDialog_data = {
        businessName: '',
        principalUserName: '',
        selectName: '',
        selectRole: '',
        costDetail: {},
        leftTableData: [],
        rightTableData: [],
      }
      this.isCostDialogLoading = true
      this.costDialog_data.businessName = scope.businessName
      this.costDialog_data.businessManageId = scope.businessManageId
      this.costDialog_data.principalUserName = scope.principalUserName
      this.costDialog_data.principalUserId = scope.principalUserId
      this.getLeftData()
      this.costDialog_show = true
      this.isCostDialogLoading = false
    },

    // 获取左侧商务报销列表数据
    async getLeftData() {
      this.costDialog_data.leftTableData = []
      this.leftCostLoading = true
      this.leftParams.businessManageId = this.costDialog_data.businessManageId
      const { data: res } = await this.$api.businessTally.listGroupBusinessOrStaff(this.leftParams)
      this.leftCostLoading = false
      if (res?.records) {
        this.leftParams.total = res.total
        this.costDialog_data.leftTableData = res.records
      }
    },

    selectUserCost(value, index) {
      this.rightCostLoading = true
      this.highlightedCell = { rowIndex: index }
      this.costDialog_data.selectId = value.targetId
      this.costDialog_data.selectName = value.targetName
      this.costDialog_data.selectRole = value.role
      this.getRightData()
    },

    async getRightData() {
      this.rightCostLoading = true
      this.costDialog_data.rightTableData = []
      this.rightParams.businessManageId = this.costDialog_data.businessManageId
      this.rightParams.targetId = this.costDialog_data.selectId
      const { data: res } = await this.$api.businessTally.listToStaff(this.rightParams)
      this.rightCostLoading = false
      if (res?.records) {
        this.rightParams.total = res.total
        this.costDialog_data.rightTableData = res.records
      }
    },

    // 左侧分页，页大小改变
    handleLeftSizeChange(val) {
      this.leftParams.pageSize = val
      this.leftParams.pageNow = 1
      this.getLeftData()
    },
    handleLeftCurrentChange(val) {
      this.leftParams.pageNow = val
      this.getLeftData()
    },

    handleRightSizeChange(val) {
      this.rightParams.pageSize = val
      this.rightParams.pageNow = 1
      this.getRightData()
    },
    handleRightCurrentChange(val) {
      this.rightParams.pageNow = val
      this.getRightData()
    },

    // 打开报销详情信息对话框
    async showCostDetail(val) {
      this.rightDetailLoading = true
      this.costDialog_data.costDetail = {}
      this.costDialog_data.moduleType = val.moduleType
      if (val.moduleType == 'BUSINESS_EXPENSE') {
        const { data: res } = await this.$api.businessExpense.selectDetailById(val.professionalId)
        this.rightDetailLoading = false
        if (res) {
          this.costDialog_data.costDetail = res
        }
      } else if (val.moduleType == 'BUSINESS_REGISTRATION_EXPENDITURE') {
        const { data: res } = await this.$api.register.selectDetailById(val.professionalId)
        this.rightDetailLoading = false
        if (res) {
          this.costDialog_data.costDetail = res
        }
      }
      this.costDialog_data.costDetail.detailList.forEach(item => {
        item.expenseFileList.forEach(v => {
          if (!this.isShowType(v)) {
            this.imgList.push(v)
          }
        })
      })
      this.costDetailDialog_show = true
    },

    handleCloseCostDetail() {
      this.costDetailDialog_show = false
    },

    isShowType(n) {
      let arr = n.filePath.split('.')
      let str = arr[arr.length - 1]
      let srtArr = [
        'bmp',
        'dib',
        'pcp',
        'dif',
        'wmf',
        'gif',
        'jpg',
        'tif',
        'eps',
        'psd',
        'cdr',
        'iff',
        'tga',
        'pcd',
        'mpt',
        'png',
        'jpeg',
      ]
      if (srtArr.indexOf(str) == -1) {
        return true
      } else {
        return false
      }
    },

    setCellClass({ rowIndex }) {
      const isHighlighted = this.highlightedCell && this.highlightedCell.rowIndex === rowIndex
      return isHighlighted ? ['highlight'] : []
    },

    closeDialog() {
      this.highlightedCell = ''
      this.costDialog_show = false
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
#costDialog {
  /deep/ .el-table th {
    background: #ddeeff;
  }
  /deep/ .undefined {
    border-bottom: 0px;
  }
  /deep/.el-table__cell {
    border-left: 1px solid #ebeef5 !important;
  }
  .cost-dialog-title {
    margin-bottom: 10px;
  }
}

.img_txt {
  text-decoration: underline;
  color: #409eff;
  &:hover {
    cursor: pointer;
  }
  .content {
    padding: 10px;
  }
}

.empty-detail {
  height: 444px;
  color: grey;
  font-size: 14px;
  text-align: center;
  position: relative;
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/deep/.el-table__body tr.current-row > td.el-table__cell {
  background: rgb(241, 212, 158);
}

/deep/.select-item {
  background: rgb(241, 212, 158);
}

::v-deep .highlight {
  background: rgb(241, 212, 158) !important;
}
</style>
